/*
* 업무구분: 신탁>영업>변경/해지/이체>이체>입금>RT출금계좌 신규등록_P
* 화 면 명: MSPTS202P [TFIO49001.xfdl]
* 화면설명: 실시간 출금계좌 신규등록
* 작 성 일: 2023.03.24
* 작 성 자: 박은희
*/

<template>
  <mo-modal class="fts-modal medium" ref="modal" title="실시간 출금계좌 신규등록">

    <div class="wrap-modalcontents">
      <div class="wrap-table">
        <table class="table col-type ">
          <colgroup>
            <col width="180px">
            <col width="*">
          </colgroup>
          <tbody>
            <tr>
              <th>
                <span class="emphasis"> 비밀번호</span>
              </th>
              <td>
                <div class="wrap-input">

                  <!-- 비밀번호 -->
                    <m-trans-key-input
                      v-if="isMobile"
                      v-model="edt_pwd"
                      class="login-password"
                      type="numberMax4"
                      maxlength="4"               
                      placeholder="비밀번호"
                      :start="'0'"
                      :end="'-1'"
                      dialog="Y"
                      :isClear="lv_isClear"
                      @masked-txt="fn_SetMaskedTxt">
                    </m-trans-key-input>

                    <mo-text-field
                      v-else
                      v-model="edt_pwd"
                      maxlength="4"                               
                      placeholder="비밀번호"
                      type="password"                    
                      @keyup="fn_PwdValid($event)" 
                      password class="login-password"/>
                    <!-- 비밀번호 -->
                 
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <span class="emphasis"> 은행명 </span>
              </th>
              <td>
                <div class="wrap-input">
                  <mo-dropdown 
                    :items="bnkItems" 
                    v-model="bkcd" 
                    :disabled="isBkcdDisabled"
                   
                    placeholder="선택하세요"
                    ref="dropdown1"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <span class="emphasis"> 출금계좌번호 </span>
              </th>
              <td>
                <div class="wrap-input row">
                  <mo-decimal-field class="w130" numeric mask="##############################" v-model="edt_bnkAcno" :disabled="isbakAcnoDisabled" />  <!-- bnkAcno -->                  
                  <mo-button class="mw80" @click="fn_CheckCNm"> 예금주확인 </mo-button>
                  <mo-text-field class="input-long" v-model="edt_bkAcNm" maxlength="20" />  <!-- bkAcNm -->
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <span class="emphasis"> 주민등록번호 </span>
              </th>
              <td>
                <div class="wrap-input row">
                  <mo-text-field v-model="edt_rcno" 
                   type="number"
                   maxlength="6"/>
                  <span class="wsN"> 주민번호 앞 6자리 </span>  <!-- rcno -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
 
    <template slot="action">
      <div class="wrap-button button-page-bottom row"> 
        <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
        <mo-button primary size="large" :disabled="isConfirmBtnDisabled" @click="fn_Confirm()">등록</mo-button>  
      </div>
    </template>

    <ts-alert-popup 
      ref="alertPopup"
      :alertPopupObj="pAlertPopupObj"
    ></ts-alert-popup>

  </mo-modal>
</template>

<script>

const defaultSearch = {
  tacno:'',
  pwd:'',
  bnkAcno:'',
  bkcd:'',
  bnkAccDepoNm:''
}
const defaultDetail00 = {
  bnkAccDepoNm:'',
  rcno:''
}

const defaultDetail = {
  tacno:'',
  pwd:'',
  bkcd:'',
  bnkAcno:'',
  bnkAccDepoNm:'',
  deporRrno:'',
  regTeleTypeYn:'',
  rltiAccRegBusnTc:'',
  rltiAccRegMedType:''
}

const defaultReport = {
  cNm:'',
  rcno:'',
  tacno:'',
  bnkNm:'',
  bnkAcno:'',
  telTyp:'',
  procCls:'',
  procDate:'',
  bnkAccDepoNm:'',
  brnm:'',
  procEnnm:'',
  phoneNum:'',
  procTime:'',
  csId:'',
  rltiAccSeq:''
}


/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'
export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPTS202P',
  // 현재 화면 ID
  screenId: 'MSPTS202P',
  // 컴포넌트 선언
  components: {
    'ts-alert-popup': TSAlertPopup,
  },
  // 화면명
  props: {
    pPage: String,      // 부모 페이지명
    pTacno: String,     // 종합계좌번호  
    pUrl: String,
    pProcTc: String,
    popupObj: {type:Object, default:null},
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 공통 변수
      lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data,
      lv_isProcess: this.getStore('tsStore').getters.getState.isProcess,
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,

      tacno: '',  
      bkcd: '',
      bnkItems: [],
      bnkList: [],

      edt_bnkAcno: '',  //출금계좌번호
      edt_bkAcNm: '',  //예금주명
      edt_bnkAccDepoNm: '',  //예금주명
      edt_rcno: '',  //주민등록번호 6자리
      edt_pwd: '',  // 비밀번호

      pAlertPopupObj: {},

      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),

      ds_search: Object.assign({}, defaultSearch),
      ds_detail00: Object.assign({}, defaultDetail00),
      ds_detail: Object.assign({}, defaultDetail),
      ds_report: Object.assign({}, defaultReport),

      isConfirmBtnDisabled: true,
      isBkcdDisabled: false,
      isbakAcnoDisabled: false, //출금계좌번호 활성화 여부 

      lv_masked_val: '',          // 마스크 변수 체크
      lv_isClear: false,          // 보안키패드 초기화

    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    modal() {return this.$refs.modal},
  },
  

  /** watch 정의 영역 */
  watch: {
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    //this.fn_ComCode() 
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPTS202P')
    //this.fn_ComCode()
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {

    // input dom numbers only
    NumbersOnly (evt) {
      evt = (evt) ? evt : window.event
      let charCode = (evt.which) ? evt.which : evt.keyCode
      if ( (charCode>31 && (charCode<48||charCode>57)) && charCode !== 46 ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_comCode(){
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600167_S'
      this.eaiCommObj.params = {
                                  cId   : '5006',
                               }
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack)
    }, 


    /******************************************************************************
     * Function명 : fn_ComCodeCallBack data.body.
     * 설명       : 공통코드 후처리
     ******************************************************************************/
    fn_comCodeCallBack(res) {

      let t_data = res.data
      for ( let index in res.data.slfsbizUtil ) {
          let item = res.data.slfsbizUtil[index]
          this.bnkItems.push({value: item.c , text: item.cnm})
      }
    },

    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
      this.fn_PwdValid()
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init (lvParam) {
      this.bkcd = ''                //은행코드
      this.bnkAcno = ''                //출금계좌번호
      this.bkAcNm = ''              //예금주명
      this.bnkItems = []
      this.edt_bnkAcno = ''   
      this.edt_bkAcNm = ''   
      this.edt_rcno   = ''      
      this.edt_pwd   = '' 

      this.isConfirmBtnDisabled = true
      this.isBkcdDisabled = false
      this.isbakAcnoDisabled = false
      
      this.edt_pwd = ""; // 비밀번호(웹일반) 정합성 체크를 위한 초기화
      this.lv_masked_val = ""; // 비밀번호(보안키패드) 정합성 체크를 위한 초기화
      this.lv_isClear = !this.lv_isClear ? true : false;  // 비밀번호(보안키패드) 정합성 체크를 위한 초기화

    },
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : open
     ******************************************************************************/
    fn_Open(properties){
      this.fn_Init()
      this.tacno = properties.pTacno  // 종합계좌번호
      this.modal.open()
      this.fn_comCode()
         
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 닫기
     ******************************************************************************/
    fn_Close(){
      this.modal.close()
      this.closeDropdown()
    },
    closeDropdown() {
      this.$refs.dropdown1.close()
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      this.fn_Init()
    },
 

   /******************************************************************************
     * Function명 : fn_CheckCNm
     * 설명       : 예금주 정보 체크   (1.가상계좌번호 체크)
     ******************************************************************************/
    fn_CheckCNm(){
      // 1. 은행을 선택해 주십시오.
      // 2. 출금 계좌 번호를 입력해 주십시오.
 
    
      /*서비스 호출시 정보 세팅 */
      if ( TSCommUtil.gfn_isNull ( this.bkcd ) )
      {
        let t_popupObj = {
          confirm: false,
          content: '은행을 선택해 주십시오.'
        }
        this.$emit('ts-alert-popup', 0, t_popupObj)
        return
      }

      if ( TSCommUtil.gfn_isNull ( this.edt_bnkAcno ) )
      {
        let t_popupObj = {
          confirm: false,
          content: '출금 계좌 번호를 입력해 주십시오.'
        }
        this.$emit('ts-alert-popup', 0, t_popupObj)
        return
      }

      this.ds_search = Object.assign({}, defaultSearch)
      this.ds_search.tacno = this.tacno
      this.ds_search.pwd = this.edt_pwd
      this.ds_search.bkcd = this.bkcd
      this.ds_search.bnkAcno = this.edt_bnkAcno

      // 정보 재설정 필요.....
      this.eaiCommObj.lv_vm = this
      // selectTFIO42250AList   AS-IS 는 해당 ID  로 은행코드 정보를 가져오나 정의서 기록에 의하면 실시간계좌 목록을 조회 을 가져온단다.
      this.eaiCommObj.trnstId = 'txTSSTS49S2' 
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600179_S'
      this.eaiCommObj.params = {
                                  // 설정 처리 필요
                                  tacno   : this.ds_search.tacno,
                                  pwd     : this.ds_search.pwd,
                                  bkcd    : this.ds_search.bkcd,
                                  bnkAcno : this.ds_search.bnkAcno,

                               }

      //console.log('예금주 확인 : ', this.eaiCommObj.params)                                
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CheckCNmCallBack)

    },

   /******************************************************************************
     * Function명 : fn_CheckCNmCallBack
     * 설명       : 예금주 정보 체크 후처리   (1.가상계좌번호 체크)
     ******************************************************************************/
    fn_CheckCNmCallBack ( pResultData){

      console.log(pResultData)
      const resData = Object.assign({}, pResultData.data)

      if ( resData.errorMsg.trim().length != 0 ) {
         if ( TSCommUtil.gfn_isNull ( resData.tacno ) )
          {
            let t_popupObj = {
              confirm: false,
              content: resData.errorMsg
            }
            this.$emit('ts-alert-popup', 0, t_popupObj)
            return false;
          }

    
      }else{

          if ( TSCommUtil.gfn_isNull ( resData.tacno ) )
          {
            let t_popupObj = {
              confirm: false,
              content: '가상계좌는 실시간계좌로 등록할수 없습니다.'
            }
            this.$emit('ts-alert-popup', 0, t_popupObj)
            return false;
          }

          this.fn_acNm()
      }
      //console.log('**** : ' + JSON.stringify(t_data))
      
    },    


   /******************************************************************************
     * Function명 : fn_acNm
     * 설명       : 통장번호 정보 조회
     ******************************************************************************/
    fn_acNm(){
      this.ds_search.bnkAccDepoNm = this.edt_bkAcNm

      //this.eaiCommObj 초기화 필요한가?
      this.eaiCommObj.lv_vm = this
      // selectTFIO49000A   AS-IS 는 해당 ID  로 은행코드 정보를 가져오나 정의서 기록에 의하면 실시간계좌 목록을 조회 을 가져온단다.
      this.eaiCommObj.trnstId = 'txTSSTS49S3' 
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600177_S'
      this.eaiCommObj.params = {
                                  // 설정 처리 필요
                                  tacno        : this.ds_search.tacno,
                                  pwd          : this.ds_search.pwd,
                                  bkcd         : this.ds_search.bkcd,
                                  bnkAcno      : this.ds_search.bnkAcno,
                                  bnkAccDepoNm : this.ds_search.bnkAccDepoNm,
                               }
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_acNmCallBack)


    },

    /******************************************************************************
     * Function명 : fn_acNmCallBack
     * 설명       : 통장번호 필수값 검증
     ******************************************************************************/
    fn_acNmCallBack( res ){
        // 정상적이면 확인 버튼 활성화
        // 은행선택박스 비활성화
        // 출금계좌 박스 비활성화
        // 예금주명 리턴(res) 처리해야함
       
        const resData = Object.assign({}, res.data)
        this.edt_bkAcNm = res.data.bnkAccDepoNm
        this.isConfirmBtnDisabled = false
        this.isBkcdDisabled       = true
        this.isbakAcnoDisabled    = true
         
    },   
    
 

    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : RT 출금계좌 등록 확인
     ******************************************************************************/
    fn_Confirm(){
      // 저장후 출력..... 
      // 최종 부모창에  처리 결과 Y 로 보내고 닫기 
      // 입력/선택 필수값 검증    
      if(this.isMobile) {
        if (TSCommUtil.gfn_isNull (this.lv_masked_val) || TSCommUtil.gfn_length (this.lv_masked_val) != 4) {
          let t_popupObj = {
            confirm: false,
            content: '비밀번호를 입력하십시오.'
          }
          this.$emit('ts-alert-popup', 0, t_popupObj)
          return false;
        } 
      } else {
        if ( TSCommUtil.gfn_isNull ( this.edt_pwd ) )
        {
          let t_popupObj = {
            confirm: false,
            content: '비밀번호를 입력하십시오.'
          }
          this.$emit('ts-alert-popup', 0, t_popupObj)
          return false;
        } 
      }    
 

      if ( TSCommUtil.gfn_isNull ( this.edt_bkAcNm ) )
      {
        let t_popupObj = {
          confirm: false,
          content: '예금주확인을 하십시오.'
        }
        this.$emit('ts-alert-popup', 0, t_popupObj)
        return false;
      }  

      if ( TSCommUtil.gfn_isNull ( this.edt_rcno ) )
      {
        let t_popupObj = {
          confirm: false,
          content: '주민번호 앞 6자리를 입력하십시오.'
        }
        this.$emit('ts-alert-popup', 0, t_popupObj)
        return false;
      }  
      
      this.ds_detail00 = Object.assign({}, defaultDetail00)

      this.ds_detail00.tacno = this.tacno
      this.ds_detail00.pwd = this.edt_pwd
      this.ds_detail00.bkcd = this.bkcd
      this.ds_detail00.bnkAcno = this.edt_bnkAcno
      this.ds_detail00.bnkAccDepoNm = this.edt_bkAcNm
      this.ds_detail00.deporRrno = this.edt_rcno
      this.ds_detail00.regTeleTypeYn = ''
      this.ds_detail00.rltiAccRegBusnTc = '04'
      this.ds_detail00.rltiAccRegMedType = 'I'

//console.log('실시간 등록 ' , this.ds_detail00)
      this.eaiCommObj.lv_vm = this
      // insertTFIO49000B   AS-IS 는 해당 ID  로 은행코드 정보를 가져오나 정의서 기록에 의하면 실시간계좌 목록을 조회 을 가져온단다.
      //this.eaiCommObj.trnstId = 'txTSSTS49I2' 
      this.eaiCommObj.auth = 'I'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600175_S'
      this.eaiCommObj.params = {
                                  // 설정 처리 필요
                                  tacno:this.ds_detail00.tacno,
                                  pwd:this.ds_detail00.pwd,
                                  bkcd:this.ds_detail00.bkcd,             // 은행코드명(은행코드 선택 value )
                                  bnkAcno:this.ds_detail00.bnkAcno,
                                  bnkAccDepoNm:this.ds_detail00.bnkAccDepoNm,    //은행명(?) edt_bkAcNm  bnkAccDepoNm
                                  deporRrno:this.ds_detail00.deporRrno,
                                  regTeleTypeYn:this.ds_detail00.regTeleTypeYn,       //화면입력값이나 현재 없음
                                  rltiAccRegBusnTc:this.ds_detail00.rltiAccRegBusnTc,  // rt등록 고정
                                  rltiAccRegMedType:this.ds_detail00.rltiAccRegMedType, // 판매시스탬 고정
                               }

      //console.log('실시간 등록 ' , this.eaiCommObj.params)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_conformCallBack)

    },    


    /******************************************************************************
     * Function명 : fn_conformCallBack
     * 설명       : 
     ******************************************************************************/
    fn_conformCallBack( res ){

      console.log('신규 등록 fn_conformCallBack res ===================> ', res)

      const resData = Object.assign({}, res.data)

      if ( !TSCommUtil.gfn_isNull ( resData.errorMsg ) )
      {
        let t_popupObj = {
          confirm: false,
          content: resData.errorMsg
        }
        this.$emit('ts-alert-popup', 0, t_popupObj)
        return false;
      }

        this.$emit('ts-popup202-callback', resData.tfio49000[0])
        //this.$emit('fn_searchList')
        this.fn_Close()
         
    },  


    /******************************************************************************
     * Function명 : fn_PwdValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_PwdValid(event) {

      if( TSCommUtil.gfn_length(this.edt_pwd) == 4 || TSCommUtil.gfn_length(this.lv_masked_val) == 4 ) {

        // 기존 검색 결과 초기화 함수 필요함
        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS49S8' // selectTFIO49000AList
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600247_S'
        this.eaiCommObj.params = {
            acctNo: this.tacno,
            pwd   : this.edt_pwd,
            pwdCls : 'N',
        }

        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_passwordCheckResult)
      }
    },
 

    /******************************************************************************
     * Function명 : fn_passwordCheckResult
     * 설명       : 비밀번호 체크 후처리
     ******************************************************************************/
    fn_passwordCheckResult(pResultData) {

      console.log('pResultData ;' , pResultData)

      if ( !TSCommUtil.gfn_isNull ( pResultData.data.errorMsg) ){
        let t_popupObj = {
          confirm: false,
          content: pResultData.data.errorMsg
        }
        this.edt_pwd = ''
        if(this.isMobile) { //Mobile 
          this.edt_pwd = ""; // 비밀번호(웹일반) 정합성 체크를 위한 초기화
          this.lv_masked_val = ""; // 비밀번호(보안키패드) 정합성 체크를 위한 초기화
          this.lv_isClear = !this.lv_isClear ? true : false;  // 비밀번호(보안키패드) 정합성 체크를 위한 초기화
        }
        this.$emit('ts-alert-popup', 0, t_popupObj)
        return false;
      } 

    },


  }
}
</script>

